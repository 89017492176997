import { useState } from "react"
import { NavLink } from "react-router-dom"

const Navbar = () => {
    const [deactivate, setDeactivate] = useState(true)
    const [isActive, setIsActive] = useState(false)
    const [hamburgerToggled, setHambuerToggled] = useState(false)

    function toggleMenu () {
        setHambuerToggled((hamburgerToggled) => !hamburgerToggled)
    }

    return (
        <header id="header">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <NavLink className="navbar-brand" to="/">
                        {/* <img src={require("../assets/imgs/logo.png")} className="img-fluid" alt="" /> */}
                        <div className="tt-logo border border-dark border-1 rounded-1 px-3 py-1 text-dark bg-white">THIRST TRAP</div>
                    </NavLink >
                    <button className="navbar-toggler collapsed" type="button"  onClick={toggleMenu} data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className={hamburgerToggled ? `fs-1 bi bi-x-lg` : `navbar-toggler-icon`}></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
                        <ul id="nav" className="navbar-nav">
                            <li className="nav-item ps-3">
                                <NavLink className={isActive ? 'nav-link active' : 'nav-link'}  to="/products">Cocktails</NavLink>
                            </li>
                            <li className="nav-item dropdown ps-3">
                                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">About</a>
                                <ul className="dropdown-menu border-0">
                                    <li className="" ><NavLink className="dropdown-item" to="/about">About Us</NavLink></li>
                                    <li className=""><NavLink className="dropdown-item" to="/team">Crew</NavLink></li>
                                </ul>                                
                            </li>
                            <li className="nav-item ps-3">
                                <NavLink className="nav-link" to="/storelocator">Where To Find Us</NavLink>
                            </li>
                            {!deactivate && <li className="nav-item ps-3">
                                <NavLink className="nav-link" to="/events">Events</NavLink>
                            </li>}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Navbar