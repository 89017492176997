import { collection, doc, setDoc } from "firebase/firestore"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import { db } from "../firebase/firebase"

const Footer = () => {
    const [deactivate, setDeactivate] = useState(true)
    const [submitted, setSubmitted] = useState(false)
    const [email, setEmail] =  useState("")

    const joinUpdates = async () => {
        const emailRef = doc(collection(db, "emails"))
        try{
            setSubmitted(true)
            await setDoc(emailRef, {
                email
            })
            setEmail("")
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <footer className="py-5 bg-secondary border-top border-dark border-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row gy-3">
                            <h2 className="fw-bold">Thirsty for more?</h2>
                            <p className="tt-stay-updated-info">Add your email address below to stay in the loop!</p>
                            {submitted ? 
                            <div className="">
                                <h4>You have signed up for our updates!</h4>
                            </div> :
                            <div className="d-flex  flex-column flex-sm-row">
                                <button className="btn bg-dark text-white p-2 me-2 mb-3" onClick={() => joinUpdates()}>Stay Updated</button>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} className="mx-2 border-0 border-bottom border-2 bg-secondary rounded-0 text-center mb-3" type="email" placeholder="Email Address"/>
                            </div>}
                        </div>
                    </div>
                    <div className="col-lg-6 d-lg-flex justify-content-end">
                        <div className="">
                            <div className="row my-4 d-flex">
                                <div className="col-6">
                                    <div className="tt-links row d-flex flex-column gy-2">
                                        <div className="col py-lg-2"><NavLink to="/about">About</NavLink></div>
                                        <div className="col py-lg-2"><NavLink to="/products">Products</NavLink></div>
                                        <div className="col py-lg-2"><NavLink to="/storelocator">Locations</NavLink></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="tt-links row d-flex flex-column  align-items-end gy-2">
                                        <div className="col px-1 py-lg-2"><NavLink to="/contact">Contact Us</NavLink></div>
                                        {!deactivate && <div className="col px-1 py-lg-2"><NavLink to="/events">Events</NavLink></div>}
                                        <div className="col px-1 py-lg-2"><NavLink to="">FAQ</NavLink></div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-lg-evenly">
                                    <NavLink to="" className="fs-4 text-dark pe-lg-0 pe-4"><i className="bi bi-facebook"></i></NavLink>
                                    <NavLink to="" className="fs-4 text-dark pe-lg-0 pe-4"><i className="bi bi-instagram"></i></NavLink>
                                    <NavLink to="" className="fs-4 text-dark "><i className="bi bi-twitter "></i></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="my-4 text-lg-center mt-4"><span className="text-dark" dangerouslySetInnerHTML={{ "__html": "&copy;" }} /> THIRST TRAP 2023. All Rights Reserved </p>
            </div>
        </footer>
    )
}

export default Footer