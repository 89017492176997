// React
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Swiper
import "swiper/css";
import "swiper/css/navigation";

// Google Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

// Components
import Navbar from "./features/navbar"
import Home from "./pages/home"
import Products from "./pages/products"
import About from "./pages/about"
import StoreLocator from './pages/storelocator';
import Socials from './features/socials';
import Footer from './features/footer';
import SingleProduct from "./components/single-product/singleproduct";
import Contact from "./pages/contact";
import Team from "./pages/team";
import ScrollToTop from "./features/scrolltotop";
import AgeVerification from "./features/ageverification";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase/firebase";
import VendorForm from "./pages/vendorform";


const App = () => {
    const [verified, setVerified] = useState(null)
    const [products, setProducts] = useState([])
    const [sortedProducts, setSortedProducts] = useState([])
    const [fanFavs, setFanFavs] = useState([])
    const analytics = getAnalytics();

    const queryProducts = async () => {
        try {
            const query = await getDocs(collection(db, "products"))
            const data = query.docs.map(doc => ({...doc.data(), id: doc.id}))
            setProducts(data)
            setFanFavs(data.filter(product => product.favorite === true).reverse())
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        const data = window.localStorage.getItem("AGE_VERIFICATION")
        if (data === "true") {
            setVerified(true)
        } else {
            setVerified(false)
        }
        queryProducts()
        logEvent(analytics, "website_traffic")
    }, [])

    useEffect(() => {
        window.localStorage.setItem("AGE_VERIFICATION", verified)
    }, [verified])

    useEffect(() => {
        if (products.length !== 0) {
            setSortedProducts(products.sort((a,b) => a.name.localeCompare(b.name)))
        }
    },[products])



    return (
        <Router>
            {verified ? <Navbar /> : null}
            <ScrollToTop />
            <Routes >
                <Route element={<AgeVerification verified={verified}  setVerified={setVerified}/>}>
                    <Route  path="/" element={<Home fanFavs={fanFavs} />} />
                    <Route  path="/products/:id"  element={<SingleProduct products={products} />} />
                    {sortedProducts.length !== 0 && <Route  path="/products"  element={<Products sortedProducts={products} />} />}
                    <Route  path="/about"  element={<About/>} />
                    <Route  path="/team"  element={<Team/>} />
                    <Route  path="/storelocator"  element={<StoreLocator/>} />
                    <Route  path="/contact"  element={<Contact />} />
                    <Route  path="/orderrequest"  element={<VendorForm />} />
                </Route>
            </Routes>
            {verified ? <Socials /> : null}
            {verified ? <Footer /> : null}
        </Router>
    )
}

export default App
